html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Roboto, Arial;
  font-size: 100%;
  font-weight: 300;
  min-width: 1024px;
  -webkit-font-smoothing: "none";
}

h1,
h2,
h3,
h4 {
  font-weight: 300;
}

h1 {
  font-size: 2.5em;
}

h2 {
  font-size: 1.875em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1.4em;
}

#root {
  height: 100%;
}

ul {
  text-align: left;
}
