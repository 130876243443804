.communication-editor {
  border: 1px solid #f1f1f1;
  padding: 5px;
  border-radius: 2px;
  height: 500px;
}

.communication-stacked {
  display: flex;
  flex-direction: column;
}
